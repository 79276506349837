import React from 'react';
import ContactForm from './ContactForm';
import Header from './Header';
import CompanySlogan from './CompanySlogan';
import './App.css';

function App() {
  
  return (
    <div className="App">
      <div className="container">
        <div
          style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
        >
          <Header />
          <div className="row middle-md" style={{ flex: 1 }}>
            <div className="col-md-7">
              <CompanySlogan />
            </div>
            <div className="col-md-5 center-md">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
