import React from 'react';

const Header = () => {
  return (
    <div
      style={{
        paddingTop: 30,
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div style={{ fontSize: 26 }}>Calvet Technology Solutions</div>
      <div>Tools</div>
    </div>
  );
};

export default Header;
