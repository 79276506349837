import React from 'react';

const CompanySlogan = () => {
  return (
    <h1 style={{ fontSize: '2.7em' }}>
      We build next generation{' '}
      <span style={{ fontWeight: 'bold' }}>software</span> solutions that
      enables your <span style={{ fontWeight: 'bold' }}>business</span> leverage
      technology <span style={{ fontWeight: 'bold' }}>efficiently</span>
    </h1>
  );
};

export default CompanySlogan;
