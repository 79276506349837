import React, { useState } from 'react';
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from 'react-toasts';
import axios from 'axios';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [ideas, setIdeas] = useState('');
  const contactUs = async () => {
    console.log('email:', email);
    console.log('ideas:', ideas);
    const response = await axios.post(
      'https://emailer.calvetsolutions.com/contact-request',
      {
        email,
        name: email,
        subject: 'contact request',
        message: ideas,
        website: 'www.calvetsolutions.com'
      }
    );
    if (response.status !== 200) {
      ToastsStore.error(
        'Something went wrong. We are looking into it! Please, Try again later.'
      );
    } else {
      ToastsStore.success(
        'We are glad to help. We will get back to you quickly!'
      );
    }
  };
  return (
    <div
      style={{
        backgroundColor: 'white',
        color: 'black',
        padding: '25px',
        borderRadius: 5
      }}
      className="column start-md"
    >
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
        lightBackground
      />
      <div style={{ fontSize: '1.5em', fontWeight: '500', marginBottom: 15 }}>
        Talk to us about your next project
      </div>
      <p
        style={{ fontSize: '0.9em', fontStyle: 'italic', lineHeight: '1.2em' }}
      >
        We have a team of developers that is ready to understand your business
        and provide you with the best software solution for your needs.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 30,
          alignItems: 'center'
        }}
      >
        <input
          style={{
            width: '100%',
            padding: 12,
            fontSize: 14,
            border: '1px gray solid',
            borderRadius: 3,
            flex: 6
          }}
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
          justifyContent: 'space-between'
        }}
      >
        <textarea
          value={ideas}
          style={{
            width: '100%',
            padding: 7,
            fontSize: 14,
            border: '1px gray solid',
            borderRadius: 3,
            flex: 6
          }}
          placeholder="Ideas"
          onChange={e => setIdeas(e.target.value)}
          cols="40"
          rows="7"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10
        }}
      >
        <button className="button" onClick={contactUs}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
